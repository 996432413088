<template>
  <CIDataTable
    :vuex="vuex"
    :settings="settings"
    :route="route"
    :isCreateButtonVisible="false"
    model="rewards"
  />
</template>
<script>
import { rewards } from '@/config/descriptions/rewards';
import { rewardsTexts } from '@/config/pageTexts/rewards.json';

export default {
  name: 'RewardsTable',
  data() {
    return {
      vuex: {
        getter: {
          theme: 'theme/theme',
          data: 'rewards/rewards',
          fields: 'rewards/fields',
          sortableFields: 'rewards/sortableFields',
          pages: 'rewards/pages',
          loading: 'rewards/loading',
          resultCount: 'rewards/resultCount',
          totalRecords: 'rewards/totalRecords',
          originalFields: 'rewards/originalFields',
          searchableAllFields: 'rewards/searchableAllFields',
          success: 'rewards/success',
          message: 'rewards/message',
        },
        actions: {
          fields: 'rewards/handleFields',
          delete: 'rewards/handleDeleteRewards',
          reset: 'rewards/handleClearFilter',
          query: 'rewards/handleGetRewardsByQuery',
        },
      },
      settings: {
        describe: { ...rewards },
        page: { ...rewardsTexts },
      },
      route: {
        create: 'CreateReward',
        edit: 'EditReward',
        preview: 'PreviewReward',
      },
    };
  },
};
</script>