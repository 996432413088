<template>
  <RewardsTable/>
</template>
<script>
import RewardsTable from './RewardsTable';

export default {
  components: {
    RewardsTable
  },
  props: {}
};
</script>